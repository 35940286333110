<template>
  <graph-page
    :loading="loading"
    :info="{
      ...info,
      people: userNum,
      amount: totalAmount,
      issueAmount: totalIssueAmount,
    }"
    :dateRange="dateRange"
    :logs="logs"
  />
</template>

<script>
import GraphPage from '@/components/GraphPage';
import { mapActions, mapState } from 'vuex';
import { ActionTypes as RootActions } from '@/store/actions';
import { ActionTypes as ConsoleActions } from '@/store/modules/console/actions';

const ONE_MONTH_DATE = 31 * 24 * 60 * 60 * 1000;

const dateFormatter = (date) => {
  if (!date) return;
  const y = date.getFullYear();
  const m = date.getMonth() + 1;
  const d = date.getDate();
  return `${y}/${m}/${d}`;
};

const calcOffset = ([from, to]) => {
  const delta = to.getTime() - from.getTime();
  const offset = delta / 1000 / 60 / 60 / 24 + 1;
  return {
    year: to.getFullYear(),
    month: to.getMonth() + 1,
    day: to.getDate(),
    offset,
  };
};

export default {
  name: 'CurrencyGraph',
  components: {
    GraphPage,
  },
  methods: {
    ...mapActions([RootActions.getCurrencyInfo]),
    ...mapActions('console', {
      getAllLog: ConsoleActions.getAllLog,
      getUserNum: ConsoleActions.getUserNum,
      getTotalAmount: ConsoleActions.getTotalAmount,
      getTotalIssueAmount: ConsoleActions.getTotalIssueAmount,
    }),
    submit() {
      this.getAllLog(calcOffset(this.dateRange));
    },
  },
  computed: {
    ...mapState({
      dateRange: 'dateRange',
    }),
    ...mapState({
      info: 'currencyInfo',
    }),
    ...mapState('console', [
      'totalAmount',
      'totalIssueAmount',
      'loading',
      'trader',
      'transaction',
      'averageDegree',
      'supplyAmount',
      'averageClusterCoefficient',
      'exponentRatio',
      'averageTravelDistance',
      'issueAmount',
      'userNum',
    ]),
    logs() {
      return [
        ['合計取引額', this.trader, '任意の期間中の取引額の合計の推移'],
        ['トランザクション数', this.transaction, '任意の期間中の取引数の推移'],
        [
          '平均次数 (無向グラフ)',
          this.averageDegree,
          '任意の期間中の１ユーザーあたりの平均取引人数の推移',
        ],
        [
          '供給量',
          this.supplyAmount,
          '任意の期間中に存在する黒字残高をもつ口座の合計金額',
        ],
        [
          '平均クラスタリング係数',
          this.averageClusterCoefficient,
          '任意の期間中の任意ユーザの取引相手を２人選んだ場合、その２人が取引関係である確率',
        ],
        [
          '通貨発行量',
          this.issueAmount,
          '任意の期間中に存在する赤字残高をもつ口座の合計金額',
        ],
        [
          'べき指数比',
          this.exponentRatio,
          '任意の期間中の全ユーザの支出と収入の累積分布から推定した傾きの比。１に近いほどバランスが良い。',
        ],
        [
          '全通貨平均移動距離',
          this.averageTravelDistance,
          '任意の期間中に送信された通貨の移動距離が１増えるとした時の貨幣単位当たりの平均の移動距離',
        ],
      ].map(([title, rows, description]) => ({
        title,
        description,
        data: {
          rows: rows.map((x) => ({
            ...x,
            date: dateFormatter(x.date),
          })),
        },
      }));
    },
  },
  watch: {
    dateRange() {
      if (this.dateRange.length === 0) {
        return;
      }

      this.getAllLog(calcOffset(this.dateRange));
    },
  },
  async created() {
    this.getTotalAmount();
    this.getTotalIssueAmount();
    this.getCurrencyInfo();
    this.getUserNum();
    this.getAllLog(calcOffset(this.dateRange));
  },
};
</script>
