<template>
  <el-card
    v-loading="loading"
    :body-style="{ paddingLeft: '40px', paddingRight: '40px' }"
  >
    <el-row :gutter="24">
      <el-col :span="12">
        <p>
          <label>通貨名</label>
          <span class="medium">{{ name }}</span>
        </p>
      </el-col>
      <el-col :span="12">
        <p>
          <label>地域</label>
          <span class="medium">{{ region }}</span>
        </p>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :span="8">
        <p>
          <label>ユーザー数</label>
          <span class="small">
            {{ people }}
            <span class="unit">人</span>
          </span>
        </p>
      </el-col>
      <el-col :span="8">
        <p>
          <label>通貨総量</label>
          <span class="small">
            {{ amount }}
            <span class="unit">{{ unit }}</span>
          </span>
        </p>
      </el-col>
      <el-col :span="8">
        <p>
          <label>通貨発行量</label>
          <span class="small">
            {{ issueAmount }}
            <span class="unit">{{ unit }}</span>
          </span>
        </p>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
export default {
  name: 'Information',
  props: {
    name: {
      type: String,
      require: true,
    }, //通貨名
    region: {
      type: String,
      require: true,
    }, //通貨リージョン
    people: {
      type: Number,
      require: true,
    }, //通貨内人数
    amount: {
      type: Number,
      require: true,
    }, //通貨総量
    issueAmount: {
      type: Number,
      require: true,
    },
    unit: {
      type: String,
      default: 'pt',
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/index.scss';
p {
  display: flex;
  flex-direction: column;

  label {
    color: $--color-primary;
    padding-bottom: 0.5rem;
  }
  .medium {
    font-size: 1.5rem;
  }
  .small {
    font-size: 1rem;
  }
}
</style>
