<template>
  <el-card :body-style="{ padding: '10px' }">
    <div class="card-header">
      <h3 class="titleplace">{{ title }}</h3>
      <el-popover
        placement="top-start"
        width="200"
        trigger="hover"
        :content="description"
      >
        <template v-slot:reference>
          <i class="el-icon-info"></i>
        </template>
      </el-popover>
    </div>
    <ve-line
      v-if="!dataNone"
      :data="chartData"
      :settings="chartSettings"
      :colors="colors"
      height="300px"
    />
    <div v-else class="no-data">データがありません</div>
  </el-card>
</template>

<script>
import VeLine from 'v-charts/src/packages/line';
export default {
  name: 'Tradechart',
  components: { VeLine },
  props: {
    title: {
      type: String,
      require: true,
    },
    chartData: {
      type: Object,
      require: true,
    },
    chartSettings: {
      type: Object,
      require: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      colors: ['#FF8000'],
    };
  },
  computed: {
    dataNone() {
      return this.chartData.rows.length === 0;
    },
  },
};
</script>

<style scoped>
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.titleplace {
  font-size: 1rem;
  height: 2rem;
  font-weight: normal;
}

.no-data {
  display: flex;
  height: 320px;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 1.5rem;
  color: #aaa;
}
</style>
