<template>
  <div>
    <information
      class="info-card"
      :name="info.name"
      :region="info.region"
      :people="info.people"
      :amount="info.amount"
      :issueAmount="info.issueAmount"
      :unit="info.unit"
      :loading="loading.info"
    />
    <div class="flex-box">
      <div v-for="log in logs" :key="log.title" class="flex-item">
        <trade-chart
          :chartSettings="chartSettings"
          :title="log.title"
          :chartData="log.data"
          :description="log.description"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TradeChart from './Traderchart';
import Information from './Information';

export default {
  components: {
    TradeChart,
    Information,
  },
  props: {
    loading: {
      type: Object,
      required: true,
    },
    info: {
      type: Object,
      required: true,
    },
    logs: {
      type: Array,
      required: true,
    },
    dateRange: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chartSettings: {
        metrics: ['value'],
        dimension: ['date'],
      },
    };
  },
  methods: {
    changeDate(x) {
      this.date = x;
    },
  },
  mounted() {
    console.log(this.logs);
  },
};
</script>

<style lang="scss" scoped>
.flex-box {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
.el-col {
  margin-bottom: 20px;
}
.info-card {
  margin-bottom: 20px;
}
.flex-item {
    flex: 1;
    min-width: 240px;
}
</style>
